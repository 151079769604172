:root {
  --bg-color-A-1980-1981: #094630;
  --border-color-A-1980-1981: #094630;
  --font-color-A-1980-1981: #fff;

  --bg-color-B-1980-1981: #73331b;
  --border-color-B-1980-1981: #73331b;
  --font-color-B-1980-1981: #fff;

  --bg-color-C-1980-1981: #404044;
  --border-color-C-1980-1981: #404044;
  --font-color-C-1980-1981: #e76f2f;

  --bg-color-D-1980-1981: #b13130;
  --border-color-D-1980-1981: #b13130;
  --font-color-D-1980-1981: #fff;
}

.LP-A-1980-1981 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: var(--border-color-A-1980-1981);
}

.Color-A-1980-1981 {
  background-color: var(--bg-color-A-1980-1981);
  color: var(--font-color-A-1980-1981);
}

.LP-B-1980-1981 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: var(--border-color-B-1980-1981);
}

.Color-B-1980-1981 {
  background-color: var(--bg-color-B-1980-1981);
  color: var(--font-color-B-1980-1981);
}

.LP-C-1980-1981 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: var(--border-color-C-1980-1981);
}

.Color-C-1980-1981 {
  background-color: var(--bg-color-C-1980-1981);
  color: var(--font-color-C-1980-1981);
}

.LP-D-1980-1981 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BC BC BC BC BC BR';
  border-color: var(--border-color-D-1980-1981);
}

.Color-D-1980-1981 {
  background-color: var(--bg-color-D-1980-1981);
  color: var(--font-color-D-1980-1981);
}
