.LicensePlateItem {
  display: grid;
  grid-template-columns: auto;
  text-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 0;
  color: white;
  /* border: solid white; */
}

.LicensePlateItem-Header {
  /* border: solid green; */
  width: inherit;
}
.LicensePlateItem-Image {
  /* border: solid red; */
  width: inherit;
}

.LicensePlateItem-Years,
.LicensePlateItem-Name {
  font-size: small;
}

.LicensePlateItem-VehicleType {
  font-size: x-small;
  padding: 2px;
}

.VehicleIcon {
  text-shadow: 1px 2px 2px var(--color-app);
}
