.LicensePlateItemsPanel {
  display: grid;
  /* height: inherit; */
  align-content: center;
  grid-template-columns: auto;
  gap: 10px;
  /* background-color: red; */
  padding: 10px;
  /* border-style: solid;
  border-color: green; */
}
img {
  width: 94%;
  height: auto;
  border-radius: 5px;
}

.MOTORCYCLE-IMG {
  /* width: 57%; */
}

.BICYCLE-IMG {
  /* width: 43%; */
}

.MetropolitanLicensePlatesPanel-Areas {
  padding-top: 3vh;
}

@media only screen and (min-device-width: 200px) {
  .LicensePlateItemsPanel {
    grid-template-columns: repeat(2, minmax(1rem, 1fr));
  }
}

@media only screen and (min-device-width: 600px) {
  .LicensePlateItemsPanel {
    grid-template-columns: repeat(3, minmax(1rem, 1fr));
  }
}

@media only screen and (min-device-width: 1000px) {
  .LicensePlateItemsPanel {
    grid-template-columns: repeat(4, minmax(1rem, 1fr));
  }
}
