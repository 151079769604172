@import url(https://fonts.googleapis.com/css?family=Roboto:900);
@import url(https://fonts.googleapis.com/css?family=Lato);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-color-app: #fff;
  --background-color: #282c34;
  --color-app: #b50d15;
  --dark-color-app: #9f131a;
  /* --background-color: #1d1e20;
  --color-app: #3d71e0;
  --dark-color-app: #255cd4; 
  */
  --sidenav-background-app: var(--dark-color-app);

  --hover-item-bg-color: #fff;
  --default-item-bg-color: var(--background-color); /*#000;*/
  --selected-item-bg-color: var(--dark-color-app); /*#006341;*/

  --hover-item-font-color: #000;
  --default-item-font-color: #fff; /*var(--dark-color-app);*/
  --selected-item-font-color: #fff;
  --filtered-item-font-color: #fff;
  --container-app-height: 100vh;
  --single-img-license-plate-width: 70vw;
}

.App {
  align-items: center;
  background-color: #282c34;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

h2 {
  text-align: center;
  color: #fff;
  color: var(--font-color-app);
  text-shadow: 1px 2px 2px #b50d15;
  text-shadow: 1px 2px 2px var(--color-app);
  margin: 15px 15px 15px 15px;
  padding: 0.5vh;
}

h3 {
  text-align: center;
  color: #fff;
  color: var(--font-color-app);
  text-shadow: 1px 2px 2px #b50d15;
  text-shadow: 1px 2px 2px var(--color-app);
  margin: 0px 0px 10px 0px;
}

/* h4,
label {
  text-align: center;
  vertical-align: middle;
  color: var(--font-color-app);
  text-shadow: 1px 2px 2px var(--color-app);
  margin: 15px 15px 15px 15px;
} */
h4 {
  text-align: center;
  text-shadow: 1px 2px 2px black;
}

@-webkit-keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  80% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(30deg);
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  80% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(30deg);
  }
}

.MexOptionsPanel {
  border-radius: 20px;
  border: 1px solid #282c34;
  border: 1px solid var(--background-color);
  /* background-color: var(--selected-item-bg-color); */
  /*   
  &:hover {
    transform: translate3d(0px, 0px, -250px);
  } */
}

.YearChoice {
  font-size: smaller;
  background-color: inherit;
  border: 2px solid #555555;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
}

.Selected {
  background-color: #9f131a;
  background-color: var(--selected-item-bg-color);
  color: var(--selected-item-fc-color);
}

.MissingItem {
  background-color: #000;
  color: var(--selected-item-fc-color);
  cursor: default;
  font-size: smaller;
}

@media only screen and (max-device-width: 500px) {
  .YearChoice {
    padding: 5px 10px;
  }
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}

.HomePage {
  margin: 10px 50px 10px 50px;
  margin: 1vh 5vw;
}

.HomePage-Logo {
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation-name: shadowAnimation;
          animation-name: shadowAnimation;
  -webkit-filter: drop-shadow(0 0 1rem white);
          filter: drop-shadow(0 0 1rem white);
  -webkit-animation: shadowAnimation 1.5s alternate infinite;
          animation: shadowAnimation 1.5s alternate infinite;
}
@-webkit-keyframes shadowAnimation {
  /* 33% {filter: drop-shadow(0 0 1.75rem green);}
  66% {filter: drop-shadow(0 0 1.75rem white);}
  99% {filter: drop-shadow(0 0 1.75rem red);} */
  100% {
    -webkit-filter: drop-shadow(0 0 1rem crimson);
            filter: drop-shadow(0 0 1rem crimson);
  }
}
@keyframes shadowAnimation {
  /* 33% {filter: drop-shadow(0 0 1.75rem green);}
  66% {filter: drop-shadow(0 0 1.75rem white);}
  99% {filter: drop-shadow(0 0 1.75rem red);} */
  100% {
    -webkit-filter: drop-shadow(0 0 1rem crimson);
            filter: drop-shadow(0 0 1rem crimson);
  }
}

.HomePage-Logo img {
  padding-top: 2vh;
  width: 30%;
  height: auto;
}

.HomePage p {
  text-align: center;
}

.AuthorLink {
  color: var(--color-app);
}

.AuthorLink a {
  color: var(--color-app);
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-device-width: 1000px) {
  .HomePage {
    font-size: large;
  }
  .HomePage-Logo img {
    width: 50%;
  }
  .HomePage p {
    text-indent: 0px;
    text-align: center;
  }
}

@media only screen and (max-device-width: 500px) {
  .HomePage {
    font-size: medium;
  }
  .HomePage-Logo img {
    width: 100%;
  }
  .HomePage p {
    text-indent: 0px;
    text-align: center;
  }
}

.Author {
  text-align: center;
  padding-bottom: 40px;
}

.UnderConstruction {
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

/* 
  body {
    padding: 3em;
  } */

h3 {
  padding-bottom: 10px;
}

.construction:after {
  background-color: #fdd400 !important; /* colour of the sign */
  /* border-radius: 3em; */
  content: '';
  /* height: 28em; */
  /* left: -12em; */
  /* position: absolute; */
  /* top: 5em; */
  /* transform: rotate(45deg); */
  /* width: 28em; */
  /* z-index: -1; */
}
.construction {
  background-color: #fdd400 !important;
  -webkit-animation: work 0.375s steps(1, start) infinite;
          animation: work 0.375s steps(1, start) infinite;
  font-size: 0.8vw;
  height: 1vh;
  margin-bottom: 37em;
  /* margin-left: 17em; */
  /* position: relative; */
  width: 1vw;

  /* Remove this is you don't care about a fallback image */

  box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
    /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
    /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
    -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em, 7em 4em,
    /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em, /* row 7 */ -6em 6em,
    -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em, -6em 7em, 0 7em, 1em 7em,
    2em 7em, 3em 7em, 9em 7em, 10em 7em, /* row 9 */ -8em 8em, -7em 8em,
    -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em, 4em 8em, 10em 8em, 11em 8em,
    /* row 10 */ -9em 9em, -8em 9em, -1em 9em, 0 9em, 1em 9em, 2em 9em, 3em 9em,
    4em 9em, 11em 9em, 12em 9em, /* row 11 */ -10em 10em, -9em 10em, -1em 10em,
    0 10em, 1em 10em, 2em 10em, 3em 10em, 4em 10em, 12em 10em, 13em 10em,
    /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
    3em 11em, 4em 11em, 13em 11em, 14em 11em, /* row 13 */ -12em 12em,
    -11em 12em, -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
    -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
    /* row 14 */ -13em 13em, -12em 13em, -8em 13em, -4em 13em, -3em 13em,
    -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
    -13em 14em, -7em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em, -1em 14em,
    0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em, -14em 15em, -7em 15em,
    -6em 15em, -5em 15em, -4em 15em, -3em 15em, -2em 15em, 0 15em, 17em 15em,
    18em 15em, /* row 17 */ -16em 16em, -15em 16em, -7em 16em, -6em 16em,
    -5em 16em, -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
    /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
    -5em 17em, -4em 17em, -3em 17em, 0 17em, 19em 17em, 20em 17em,
    /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
    -5em 18em, -4em 18em, -2em 18em, 0 18em, 19em 18em, 20em 18em,
    /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
    -5em 19em, -4em 19em, -1em 19em, 0 19em, 19em 19em, 20em 19em,
    /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
    -5em 20em, -4em 20em, -3em 20em, 0 20em, 19em 20em, 20em 20em,
    /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
    -5em 21em, -4em 21em, -3em 21em, -2em 21em, 1em 21em, 18em 21em, 19em 21em,
    /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
    -4em 22em, -3em 22em, -2em 22em, -1em 22em, 2em 22em, 17em 22em, 18em 22em,
    /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
    -3em 23em, -2em 23em, -1em 23em, 3em 23em, 16em 23em, 17em 23em,
    /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
    -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 15em 24em,
    16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
    -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
    7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
    -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
    5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
    /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
    -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
    7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
    -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
    4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
    12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
    /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
    /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
    /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
    /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
    /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
    /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
    /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
    /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
}

@media only screen and (max-device-width: 500px) {
  .construction {
    font-size: 1.4vw;
  }
}

/* Animation */

@-webkit-keyframes work {
  25% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
      -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -8em 12em,
      -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em, -2em 12em, 0 12em,
      1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -8em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
      -13em 14em, -7em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em,
      -1em 14em, 0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em, -3em 15em,
      -2em 15em, 0 15em, 17em 15em, 18em 15em, /* row 17 */ -16em 16em,
      -15em 16em, -7em 16em, -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em,
      18em 16em, 19em 16em, /* row 18 */ -17em 17em, -16em 17em, -8em 17em,
      -7em 17em, -6em 17em, -5em 17em, -4em 17em, -3em 17em, 0 17em, 19em 17em,
      20em 17em, /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em,
      -6em 18em, -5em 18em, -4em 18em, -2em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, -1em 19em, 0 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 0 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 1em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 2em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 3em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
      /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
      -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
      7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
      -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
      4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
      12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
      /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
      /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }
  50% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
      -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -9em 12em,
      -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
      -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -9em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
      -13em 14em, -8em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em,
      -1em 14em, 0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em, -3em 15em,
      -2em 15em, 0 15em, 17em 15em, 18em 15em, /* row 17 */ -16em 16em,
      -15em 16em, -7em 16em, -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em,
      18em 16em, 19em 16em, /* row 18 */ -17em 17em, -16em 17em, -8em 17em,
      -7em 17em, -6em 17em, -5em 17em, -4em 17em, -2em 17em, 0 17em, 19em 17em,
      20em 17em, /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em,
      -6em 18em, -5em 18em, -4em 18em, -1em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, 1em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 3em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 17em 22em, 18em 22em, /* row 24 */ -14em 23em, -13em 23em,
      -8em 23em, -7em 23em, -6em 23em, -3em 23em, -2em 23em, -1em 23em, 4em 23em,
      5em 23em, 6em 23em, 7em 23em, 8em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 7em 24em,
      8em 24em, 15em 24em, 16em 24em, /* row 26 */ -12em 25em, -11em 25em,
      -8em 25em, -7em 25em, -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em,
      5em 25em, 6em 25em, 7em 25em, 8em 25em, 14em 25em, 15em 25em,
      /* row 27 */ -11em 26em, -10em 26em, -8em 26em, -7em 26em, -6em 26em,
      -3em 26em, -2em 26em, 3em 26em, 4em 26em, 5em 26em, 6em 26em, 7em 26em,
      8em 26em, 13em 26em, 14em 26em, /* row 28 */ -10em 27em, -9em 27em,
      -7em 27em, -6em 27em, -3em 27em, -2em 27em, -1em 27em, 2em 27em, 3em 27em,
      4em 27em, 5em 27em, 6em 27em, 7em 27em, 8em 27em, 9em 27em, 12em 27em,
      13em 27em, /* row 29 */ -9em 28em, -8em 28em, -6em 28em, -3em 28em,
      -2em 28em, -1em 28em, 2em 28em, 3em 28em, 4em 28em, 5em 28em, 6em 28em,
      7em 28em, 8em 28em, 9em 28em, 11em 28em, 12em 28em, /* row 30 */ -8em 29em,
      -7em 29em, 10em 29em, 11em 29em, /* row 31 */ -7em 30em, -6em 30em,
      9em 30em, 10em 30em, /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }

  75% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
      -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -9em 12em,
      -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
      -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -9em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
      -13em 14em, -9em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em,
      -1em 14em, 0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -9em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em,
      -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -9em 16em, -8em 16em, -7em 16em,
      -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -3em 18em, -2em 18em, 0 18em, 6em 18em, 7em 18em,
      19em 18em, 20em 18em, /* row 20 */ -17em 19em, -16em 19em, -8em 19em,
      -7em 19em, -6em 19em, -5em 19em, -4em 19em, -1em 19em, 0 19em, 1em 19em,
      5em 19em, 6em 19em, 7em 19em, 8em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 3em 20em, 4em 20em, 5em 20em,
      6em 20em, 7em 20em, 8em 20em, 9em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 4em 21em, 5em 21em, 6em 21em,
      7em 21em, 8em 21em, 9em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 8em 22em, 9em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 7em 23em, 8em 23em, 9em 23em, 16em 23em,
      17em 23em, /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em,
      -6em 24em, -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
      /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
      -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
      7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
      -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
      4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
      12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
      /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
      /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }
  100% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 9em 10em, 10em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 7em 11em, 9em 11em, 10em 11em, 13em 11em, 14em 11em,
      /* row 13 */ -12em 12em, -11em 12em, -9em 12em, -8em 12em, -7em 12em,
      -6em 12em, -5em 12em, -4em 12em, -3em 12em, -2em 12em, 0 12em, 1em 12em,
      2em 12em, 3em 12em, 14em 12em, 15em 12em, /* row 14 */ -13em 13em,
      -12em 13em, -9em 13em, -4em 13em, -3em 13em, -2em 13em, -1em 13em,
      8em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em, -13em 14em,
      -9em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em, -1em 14em, 0 14em,
      8em 14em, 10em 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -9em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em,
      -3em 15em, -2em 15em, 0 15em, 6em 15em, 10em 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -9em 16em, -7em 16em, -6em 16em,
      -5em 16em, -4em 16em, -3em 16em, 0 16em, 6em 16em, 8em 16em, 18em 16em,
      19em 16em, /* row 18 */ -17em 17em, -16em 17em, -9em 17em, -8em 17em,
      -7em 17em, -6em 17em, -5em 17em, -4em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -9em 18em, -8em 18em, -7em 18em,
      -6em 18em, -5em 18em, -4em 18em, -3em 18em, -2em 18em, -1em 18em, 0 18em,
      1em 18em, 2em 18em, 3em 18em, 4em 18em, 5em 18em, 6em 18em, 7em 18em,
      8em 18em, 9em 18em, 10em 18em, 11em 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, 0 19em, 6em 19em, 7em 19em, 8em 19em, 9em 19em,
      10em 19em, 19em 19em, 20em 19em, /* row 21 */ -17em 20em, -16em 20em,
      -8em 20em, -7em 20em, -6em 20em, -5em 20em, -4em 20em, -3em 20em, 6em 20em,
      7em 20em, 8em 20em, 9em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em, 16em 24em,
      /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em, -6em 25em,
      -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em, 7em 25em,
      14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em, -8em 26em,
      -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em, 5em 26em,
      6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
      /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
      -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
      7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
      -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
      4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
      12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
      /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
      /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }
}

@keyframes work {
  25% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
      -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -8em 12em,
      -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em, -2em 12em, 0 12em,
      1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -8em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
      -13em 14em, -7em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em,
      -1em 14em, 0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em, -3em 15em,
      -2em 15em, 0 15em, 17em 15em, 18em 15em, /* row 17 */ -16em 16em,
      -15em 16em, -7em 16em, -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em,
      18em 16em, 19em 16em, /* row 18 */ -17em 17em, -16em 17em, -8em 17em,
      -7em 17em, -6em 17em, -5em 17em, -4em 17em, -3em 17em, 0 17em, 19em 17em,
      20em 17em, /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em,
      -6em 18em, -5em 18em, -4em 18em, -2em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, -1em 19em, 0 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 0 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 1em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 2em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 3em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
      /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
      -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
      7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
      -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
      4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
      12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
      /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
      /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }
  50% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
      -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -9em 12em,
      -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
      -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -9em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
      -13em 14em, -8em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em,
      -1em 14em, 0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em, -3em 15em,
      -2em 15em, 0 15em, 17em 15em, 18em 15em, /* row 17 */ -16em 16em,
      -15em 16em, -7em 16em, -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em,
      18em 16em, 19em 16em, /* row 18 */ -17em 17em, -16em 17em, -8em 17em,
      -7em 17em, -6em 17em, -5em 17em, -4em 17em, -2em 17em, 0 17em, 19em 17em,
      20em 17em, /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em,
      -6em 18em, -5em 18em, -4em 18em, -1em 18em, 0 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, 1em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 3em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 17em 22em, 18em 22em, /* row 24 */ -14em 23em, -13em 23em,
      -8em 23em, -7em 23em, -6em 23em, -3em 23em, -2em 23em, -1em 23em, 4em 23em,
      5em 23em, 6em 23em, 7em 23em, 8em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 4em 24em, 5em 24em, 6em 24em, 7em 24em,
      8em 24em, 15em 24em, 16em 24em, /* row 26 */ -12em 25em, -11em 25em,
      -8em 25em, -7em 25em, -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em,
      5em 25em, 6em 25em, 7em 25em, 8em 25em, 14em 25em, 15em 25em,
      /* row 27 */ -11em 26em, -10em 26em, -8em 26em, -7em 26em, -6em 26em,
      -3em 26em, -2em 26em, 3em 26em, 4em 26em, 5em 26em, 6em 26em, 7em 26em,
      8em 26em, 13em 26em, 14em 26em, /* row 28 */ -10em 27em, -9em 27em,
      -7em 27em, -6em 27em, -3em 27em, -2em 27em, -1em 27em, 2em 27em, 3em 27em,
      4em 27em, 5em 27em, 6em 27em, 7em 27em, 8em 27em, 9em 27em, 12em 27em,
      13em 27em, /* row 29 */ -9em 28em, -8em 28em, -6em 28em, -3em 28em,
      -2em 28em, -1em 28em, 2em 28em, 3em 28em, 4em 28em, 5em 28em, 6em 28em,
      7em 28em, 8em 28em, 9em 28em, 11em 28em, 12em 28em, /* row 30 */ -8em 29em,
      -7em 29em, 10em 29em, 11em 29em, /* row 31 */ -7em 30em, -6em 30em,
      9em 30em, 10em 30em, /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }

  75% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 12em 10em, 13em 10em, /* row 12 */ -11em 11em,
      -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em, 3em 11em, 4em 11em,
      13em 11em, 14em 11em, /* row 13 */ -12em 12em, -11em 12em, -9em 12em,
      -8em 12em, -7em 12em, -6em 12em, -5em 12em, -4em 12em, -3em 12em,
      -2em 12em, 0 12em, 1em 12em, 2em 12em, 3em 12em, 14em 12em, 15em 12em,
      /* row 14 */ -13em 13em, -12em 13em, -9em 13em, -4em 13em, -3em 13em,
      -2em 13em, -1em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em,
      -13em 14em, -9em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em,
      -1em 14em, 0 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -9em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em,
      -3em 15em, -2em 15em, 0 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -9em 16em, -8em 16em, -7em 16em,
      -6em 16em, -5em 16em, -4em 16em, -3em 16em, 0 16em, 18em 16em, 19em 16em,
      /* row 18 */ -17em 17em, -16em 17em, -8em 17em, -7em 17em, -6em 17em,
      -5em 17em, -4em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -8em 18em, -7em 18em, -6em 18em,
      -5em 18em, -4em 18em, -3em 18em, -2em 18em, 0 18em, 6em 18em, 7em 18em,
      19em 18em, 20em 18em, /* row 20 */ -17em 19em, -16em 19em, -8em 19em,
      -7em 19em, -6em 19em, -5em 19em, -4em 19em, -1em 19em, 0 19em, 1em 19em,
      5em 19em, 6em 19em, 7em 19em, 8em 19em, 19em 19em, 20em 19em,
      /* row 21 */ -17em 20em, -16em 20em, -8em 20em, -7em 20em, -6em 20em,
      -5em 20em, -4em 20em, -3em 20em, 2em 20em, 3em 20em, 4em 20em, 5em 20em,
      6em 20em, 7em 20em, 8em 20em, 9em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 4em 21em, 5em 21em, 6em 21em,
      7em 21em, 8em 21em, 9em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 4em 22em, 5em 22em, 6em 22em,
      7em 22em, 8em 22em, 9em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 7em 23em, 8em 23em, 9em 23em, 16em 23em,
      17em 23em, /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em,
      -6em 24em, -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em,
      16em 24em, /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em,
      -6em 25em, -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em,
      7em 25em, 14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em,
      -8em 26em, -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em,
      5em 26em, 6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
      /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
      -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
      7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
      -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
      4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
      12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
      /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
      /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }
  100% {
    box-shadow:
      /* row 1 */ inset 0 0 0 1em, 1em 0, 2em 0, 3em 0,
      /* row 2 */ -1em 1em, 0 1em, 1em 1em, 2em 1em, 3em 1em, 4em 1em,
      /* row 3 */ -2em 2em, -1em 2em, 4em 2em, 5em 2em, /* row 4 */ -3em 3em,
      -2em 3em, 5em 3em, 6em 3em, /* row 5 */ -4em 4em, -3em 4em, 6em 4em,
      7em 4em, /* row 6 */ -5em 5em, -4em 5em, 7em 5em, 8em 5em,
      /* row 7 */ -6em 6em, -5em 6em, 8em 6em, 9em 6em, /* row 8 */ -7em 7em,
      -6em 7em, 0 7em, 1em 7em, 2em 7em, 3em 7em, 9em 7em, 10em 7em,
      /* row 9 */ -8em 8em, -7em 8em, -1em 8em, 0 8em, 1em 8em, 2em 8em, 3em 8em,
      4em 8em, 10em 8em, 11em 8em, /* row 10 */ -9em 9em, -8em 9em, -1em 9em,
      0 9em, 1em 9em, 2em 9em, 3em 9em, 4em 9em, 11em 9em, 12em 9em,
      /* row 11 */ -10em 10em, -9em 10em, -1em 10em, 0 10em, 1em 10em, 2em 10em,
      3em 10em, 4em 10em, 9em 10em, 10em 10em, 12em 10em, 13em 10em,
      /* row 12 */ -11em 11em, -10em 11em, -1em 11em, 0 11em, 1em 11em, 2em 11em,
      3em 11em, 4em 11em, 7em 11em, 9em 11em, 10em 11em, 13em 11em, 14em 11em,
      /* row 13 */ -12em 12em, -11em 12em, -9em 12em, -8em 12em, -7em 12em,
      -6em 12em, -5em 12em, -4em 12em, -3em 12em, -2em 12em, 0 12em, 1em 12em,
      2em 12em, 3em 12em, 14em 12em, 15em 12em, /* row 14 */ -13em 13em,
      -12em 13em, -9em 13em, -4em 13em, -3em 13em, -2em 13em, -1em 13em,
      8em 13em, 15em 13em, 16em 13em, /* row 15 */ -14em 14em, -13em 14em,
      -9em 14em, -5em 14em, -4em 14em, -3em 14em, -2em 14em, -1em 14em, 0 14em,
      8em 14em, 10em 14em, 16em 14em, 17em 14em, /* row 16 */ -15em 15em,
      -14em 15em, -9em 15em, -7em 15em, -6em 15em, -5em 15em, -4em 15em,
      -3em 15em, -2em 15em, 0 15em, 6em 15em, 10em 15em, 17em 15em, 18em 15em,
      /* row 17 */ -16em 16em, -15em 16em, -9em 16em, -7em 16em, -6em 16em,
      -5em 16em, -4em 16em, -3em 16em, 0 16em, 6em 16em, 8em 16em, 18em 16em,
      19em 16em, /* row 18 */ -17em 17em, -16em 17em, -9em 17em, -8em 17em,
      -7em 17em, -6em 17em, -5em 17em, -4em 17em, 0 17em, 19em 17em, 20em 17em,
      /* row 19 */ -17em 18em, -16em 18em, -9em 18em, -8em 18em, -7em 18em,
      -6em 18em, -5em 18em, -4em 18em, -3em 18em, -2em 18em, -1em 18em, 0 18em,
      1em 18em, 2em 18em, 3em 18em, 4em 18em, 5em 18em, 6em 18em, 7em 18em,
      8em 18em, 9em 18em, 10em 18em, 11em 18em, 19em 18em, 20em 18em,
      /* row 20 */ -17em 19em, -16em 19em, -8em 19em, -7em 19em, -6em 19em,
      -5em 19em, -4em 19em, 0 19em, 6em 19em, 7em 19em, 8em 19em, 9em 19em,
      10em 19em, 19em 19em, 20em 19em, /* row 21 */ -17em 20em, -16em 20em,
      -8em 20em, -7em 20em, -6em 20em, -5em 20em, -4em 20em, -3em 20em, 6em 20em,
      7em 20em, 8em 20em, 9em 20em, 19em 20em, 20em 20em,
      /* row 22 */ -16em 21em, -15em 21em, -8em 21em, -7em 21em, -6em 21em,
      -5em 21em, -4em 21em, -3em 21em, -2em 21em, 18em 21em, 19em 21em,
      /* row 23 */ -15em 22em, -14em 22em, -8em 22em, -7em 22em, -6em 22em,
      -4em 22em, -3em 22em, -2em 22em, -1em 22em, 17em 22em, 18em 22em,
      /* row 24 */ -14em 23em, -13em 23em, -8em 23em, -7em 23em, -6em 23em,
      -3em 23em, -2em 23em, -1em 23em, 16em 23em, 17em 23em,
      /* row 25 */ -13em 24em, -12em 24em, -8em 24em, -7em 24em, -6em 24em,
      -3em 24em, -2em 24em, -1em 24em, 5em 24em, 6em 24em, 15em 24em, 16em 24em,
      /* row 26 */ -12em 25em, -11em 25em, -8em 25em, -7em 25em, -6em 25em,
      -3em 25em, -2em 25em, -1em 25em, 4em 25em, 5em 25em, 6em 25em, 7em 25em,
      14em 25em, 15em 25em, /* row 27 */ -11em 26em, -10em 26em, -8em 26em,
      -7em 26em, -6em 26em, -3em 26em, -2em 26em, 3em 26em, 4em 26em, 5em 26em,
      6em 26em, 7em 26em, 8em 26em, 13em 26em, 14em 26em,
      /* row 28 */ -10em 27em, -9em 27em, -7em 27em, -6em 27em, -3em 27em,
      -2em 27em, -1em 27em, 2em 27em, 3em 27em, 4em 27em, 5em 27em, 6em 27em,
      7em 27em, 8em 27em, 9em 27em, 12em 27em, 13em 27em, /* row 29 */ -9em 28em,
      -8em 28em, -6em 28em, -3em 28em, -2em 28em, -1em 28em, 2em 28em, 3em 28em,
      4em 28em, 5em 28em, 6em 28em, 7em 28em, 8em 28em, 9em 28em, 11em 28em,
      12em 28em, /* row 30 */ -8em 29em, -7em 29em, 10em 29em, 11em 29em,
      /* row 31 */ -7em 30em, -6em 30em, 9em 30em, 10em 30em,
      /* row 32 */ -6em 31em, -5em 31em, 8em 31em, 9em 31em,
      /* row 33 */ -5em 32em, -4em 32em, 7em 32em, 8em 32em,
      /* row 34 */ -4em 33em, -3em 33em, 6em 33em, 7em 33em,
      /* row 35 */ -3em 34em, -2em 34em, 5em 34em, 6em 34em,
      /* row 36 */ -2em 35em, -1em 35em, 4em 35em, 5em 35em,
      /* row 37 */ -1em 36em, 0 36em, 1em 36em, 2em 36em, 3em 36em, 4em 36em,
      /* row 38 */ 0 37em, 1em 37em, 2em 37em, 3em 37em;
  }
}

.Error {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: large;
  font-family: 'Open Sans', sans-serif;
  height: inherit;
  justify-content: center;
  text-align: center;
  width: inherit;
}

.Error-Message {
  color: var(--font-color-app);
  margin: 40px 20px 40px 20px;
  font-size: larger;
}

.Error-Label span {
  color: var(--font-color-app);
  font-size: medium;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: bolder;
  /* transition: all 0.5s; */
  /* cursor: pointer; */
  -webkit-animation-name: errorBackgroundAnimation;
          animation-name: errorBackgroundAnimation;
  -webkit-animation: errorBackgroundAnimation 4s alternate infinite;
          animation: errorBackgroundAnimation 4s alternate infinite;
}

@-webkit-keyframes errorBackgroundAnimation {
  33% {
    background: var(--color-app);
  }
  66% {
    background: var(--background-color);
  }
  99% {
    background: var(--color-app);
  }
}

@keyframes errorBackgroundAnimation {
  33% {
    background: var(--color-app);
  }
  66% {
    background: var(--background-color);
  }
  99% {
    background: var(--color-app);
  }
}

.ErrorButton {
  /* position: relative; */
  background: var(--color-app) !important;
  color: var(--font-color-app) !important;
  border-color: var(--color-app) !important;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: bolder;
  transition: all 0.5s;
  cursor: pointer;
  font-size: small !important;
  box-shadow: 5px 5px 2px #000;
  -moz-box-shadow: 5px 5px 2px #000;
  -webkit-box-shadow: 5px 5px 2px #000;
  -khtml-box-shadow: 5px 5px 2px #000;
  border-radius: 5px;
}

.ErrorButton:hover {
  background: var(--dark-color-app) !important;
  color: var(--font-color-app) !important;
  -webkit-animation: jelly 0.5s !important;
  animation: jelly 0.5s !important;
}

.Header {
  width: 100%;
  justify-content: 'center';
  margin-top: 10px;
  margin-bottom: 20px;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: var(--font-color-app);
  letter-spacing: 1rem;
  font-size: 120%;
  font-family: Lato, sans-serif;
  text-transform: uppercase;
}

.Loader-Span {
  -webkit-animation: blur 1.5s alternate infinite;
          animation: blur 1.5s alternate infinite;
}

@-webkit-keyframes blur {
  to {
    -webkit-filter: blur(5px);
            filter: blur(5px);
  }
}

@keyframes blur {
  to {
    -webkit-filter: blur(5px);
            filter: blur(5px);
  }
}

.SearchResults {
  /* width: max-content; */
  margin-top: 15px;
  text-align: center;
  display: block;
  align-items: center;
  /* border: solid; */
  width: auto;
  overflow: auto;
  padding-top: 10px;
}
.SearchLicensePlatesPanel-Submit {
  /* display: flex; */
  text-align: center;
  display: block;
  align-items: center;
}

.OptionsPanel-SelectYear,
.OptionsPanel-SelectRegion {
  padding-bottom: 10px;
}
.SearchButton {
  margin-bottom: -10px;
  height: 35px;
  width: 20vw;
  text-align: center;
  font-size: small;
  border-radius: 5px;
  border: 1px solid black !important;

  color: var(--font-color-app) !important;
  background-color: var(--dark-color-app) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.ActiveButton {
  color: var(--font-color-app) !important;
  background-color: var(--dark-color-app) !important;
}

.SearchButton:hover {
  background-color: var(--color-app) !important;
  /* color: white; */
}

.OptionsPanel {
  font-size: small;
  text-align: center;
}

.OptionsPanel-SelectRegion {
  width: inherit;
  display: block;
  align-items: center;
}
.react-select-container {
  width: 100%;
  border: 1px solid black;
}

.OptionsPanel-SelectYear {
  width: inherit;
  text-align: center;
}

.OptionsPanel-SelectYear-Range {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-content: space-evenly;
  justify-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin: 0px;
  align-items: center;
  text-align: center;
}

.InputYear {
  height: 30px;
  width: 30vw;
  text-align: center;
  font-size: small;
  border-radius: 5px;
  border: 1px solid black;
}

@media only screen and (max-device-width: 500px) {
  .OptionsPanel {
    font-size: xx-small;
  }
  .InputYear {
    font-size: xx-small;
  }
}

.OptionsPanel-VehicleTypes {
  margin-bottom: 10px;
}

.form-check-input {
  background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%239f131a%27/></svg>') !important;
}
.form-check-input:checked {
  background-color: var(--dark-color-app) !important;
  border-color: var(--dark-color-app) !important;
  background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23FFF%27/></svg>') !important;
}

.form-check-input:focus {
  border-color: var(--dark-color-app) !important;
  outline: 0;
  box-shadow: none !important;
}

.OptionsPanel-MainChoice {
  width: 85%;
  margin-left: 10%;
  margin-right: 10%;
  display: inline-grid;
  grid-template-columns: auto;
  align-content: space-evenly;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-size: small;
}

.OptionsPanel-FormVehicleTypes {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-content: space-evenly;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-size: large;
}

@media only screen and (max-device-width: 1000px) {
  .OptionsPanel-FormVehicleTypes {
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto;
    font-size: large;
  }
}

@media only screen and (max-device-width: 500px) {
  .OptionsPanel-FormVehicleTypes {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    font-size: medium;
  }
}

.MxMap {
  display: grid;
  /* align-content: left; */
  grid-template-columns: auto;
  justify-content: center;
  /* border-style:solid; */
  /* width: 50%; */

  /* width: 99%; */
  margin: 0px 10px 0px 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.MxMap-Geo {
  display: flex;
  width: 33vw;
  height: auto;
  align-content: center;
  text-align: center;
  justify-content: center;
  /* border-style: double;
  border-color: red; */
}

@media only screen and (max-device-width: 400px) {
  .MxMap-Geo {
    width: 65vw;
  }
}

.LicensePlateItem {
  display: grid;
  grid-template-columns: auto;
  text-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 0;
  color: white;
  /* border: solid white; */
}

.LicensePlateItem-Header {
  /* border: solid green; */
  width: inherit;
}
.LicensePlateItem-Image {
  /* border: solid red; */
  width: inherit;
}

.LicensePlateItem-Years,
.LicensePlateItem-Name {
  font-size: small;
}

.LicensePlateItem-VehicleType {
  font-size: x-small;
  padding: 2px;
}

.VehicleIcon {
  text-shadow: 1px 2px 2px var(--color-app);
}

.LicensePlateItemsPanel {
  display: grid;
  /* height: inherit; */
  align-content: center;
  grid-template-columns: auto;
  grid-gap: 10px;
  gap: 10px;
  /* background-color: red; */
  padding: 10px;
  /* border-style: solid;
  border-color: green; */
}
img {
  width: 94%;
  height: auto;
  border-radius: 5px;
}

.MOTORCYCLE-IMG {
  /* width: 57%; */
}

.BICYCLE-IMG {
  /* width: 43%; */
}

.MetropolitanLicensePlatesPanel-Areas {
  padding-top: 3vh;
}

@media only screen and (min-device-width: 200px) {
  .LicensePlateItemsPanel {
    grid-template-columns: repeat(2, minmax(1rem, 1fr));
  }
}

@media only screen and (min-device-width: 600px) {
  .LicensePlateItemsPanel {
    grid-template-columns: repeat(3, minmax(1rem, 1fr));
  }
}

@media only screen and (min-device-width: 1000px) {
  .LicensePlateItemsPanel {
    grid-template-columns: repeat(4, minmax(1rem, 1fr));
  }
}

.MissingDetailsPanel-Body {
  border-style: double;
  border-color: var(--selected-item-font-color);
  padding-top: 15px;
}

.Collapsible {
  padding-top: 10px;
  margin: 10px;
  padding-bottom: 10px;
  align-content: center;
  text-align: center;
  display: block;
  justify-content: center;
  font-size: small;
}

.Collapsible__trigger {
  padding: 10px 15px;
  width: 100%;
  border-radius: 25px;
  font-size: small;
  background-color: var(--dark-color-app);
}

.NewsPanel {
  display: grid;
  /* height: inherit; */
  align-content: center;
  text-align: center;
  grid-template-columns: auto;
}
.NewsPanel-Carousel {
  display: flex;
  justify-content: center;
  /* padding-top: 0PX; */
  padding-bottom: 10px;
}

.Vechicle-Icons {
  padding-top: 10px;
  padding-bottom: 5px;
}
.PlatesCarousel {
  height: auto;
  box-shadow: 10px 10px 5px #000;
  -moz-box-shadow: 10px 10px 5px #000;
  -webkit-box-shadow: 10px 10px 5px #000;
  -khtml-box-shadow: 10px 10px 5px #000;
  border-radius: 5px;
}
.PlatesCarousel img {
  border-radius: 5px;
}

.CAR {
  width: 40%;
}
.MOTORCYCLE {
  width: 25%;
}

.BICYCLE {
  width: 15%;
}

.accordion-item {
  background-color: var(--background-color) !important;
  /* background-color: rgba(255, 255, 255, 0.9)  !important; */
  border-color: var(--background-color) !important;
  color: white !important;
  /* width: 80%; */
  font-size: medium !important;
  padding-bottom: 10px !important;
}
.accordion-body {
  padding: 0 10px !important;
}

.accordion-button:not(.show) {
  color: #ffffff !important;
  background-color: var(--dark-color-app) !important;
  border-color: var(--dark-color-app) !important;
  /* box-shadow: red !important; */

  /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
  padding: 10px !important;

  font-weight: bolder;
  font-size: large !important;
}
.NewsImage {
  width: 40%;
  height: auto;
  box-shadow: 10px 10px 5px #000;
  -moz-box-shadow: 10px 10px 5px #000;
  -webkit-box-shadow: 10px 10px 5px #000;
  -khtml-box-shadow: 10px 10px 5px #000;
  border-radius: 5px;
}

.accordion-button:active {
  background-color: white !important;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

@media only screen and (max-device-width: 500px) {
  .CAR {
    width: 70%;
  }
  .MOTORCYCLE {
    width: 55%;
  }

  .BICYCLE {
    width: 40%;
  }
  .accordion-item {
    font-size: smaller !important;
  }
  .accordion-button:not(.show) {
    font-size: small !important;
  }
  .NewsImage {
    width: 80%;
  }
}

.LicensePlateInformationPanel-Options {
  text-align: center;
  padding: 2vh;
  width: 100%;
}
.LicensePlateInformationPanel-Figures {
  padding-top: 1vh;
}

.dropdown > button {
  background-color: var(--dark-color-app);
  color: var(--font-color-app);
  font-size: medium;
}

.Body {
  font-size: 1.6em;
  line-height: 1.15em;
}
.Footer {
  font-size: 0.7em;
}
.Header {
  font-size: 0.7em;
}

.SmallText {
  /* padding: 0.1em; */
  font-size: 0.55em;
  line-height: 2.3em;
  font-weight: bold;
}
.X-SmallText {
  font-size: 0.35em;
  line-height: 1.3em;
  font-weight: bold;
  margin: 0;
}

.HorizontalText {
  /* padding: 0.1em; */
  font-size: 0.8em;
  line-height: 1.7em;
  padding: 0;
  text-align: left;
}

.VerticalText {
  font-size: 1.4em;
  line-height: 0.9em;
  font-weight: bold;
}

.TL {
  grid-area: TL;
  padding: 0em;
}
.TC {
  grid-area: TC;
  padding: 0em;
}
.TR {
  grid-area: TR;
  padding: 0em;
}

.ML {
  grid-area: ML;
  padding: 0em;
}
.MC {
  grid-area: MC;
  padding: 0em;
}
.MR {
  grid-area: MR;
  padding: 0em;
}

.BL {
  grid-area: BL;
}
.BC {
  grid-area: BC;
}
.BR {
  grid-area: BR;
}

.LicensePlateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
}

.LicensePlate {
  border-radius: 5px;
  padding: 0.1em 0.1em;
  text-align: center;
  font-family: Sans-serif;
  text-shadow: 0px 0.05em 0px #808080;
  /* text-shadow: 0px 0.07em 0px #808080; */
  width: 7.3em;

  /* Shadows */
  box-shadow: 10px 10px 5px #000;
  -moz-box-shadow: 10px 10px 5px #000;
  -webkit-box-shadow: 10px 10px 5px #000;
  -khtml-box-shadow: 10px 10px 5px #000;
  border-radius: 5px;
}

.LP {
  display: grid;
  padding: 0.08em 0.1em;
  border: 0.1em solid white;
}

.LicensePlateFigures {
  text-align: center;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
td {
  padding-bottom: 2vh;
}

.VehicleTypeHeader {
  font-size: medium;
}
td > .VehicleTypeHeader {
  display: none;
}

@media only screen and (max-width: 760px) {
  thead {
    display: none;
  }
  td > .VehicleTypeHeader {
    display: block;
  }
  table {
    border-collapse: collapse;
  }
  table,
  tbody,
  tr,
  td {
    display: block;
    width: 100%;
  }
  tr {
    overflow: hidden;
  }
  td {
    padding: 1vh;
  }
  td.cell-time,
  td.cell-pax,
  td.cell-name,
  td.cell-mobile {
    float: left;
    width: 50%;
  }
}

.SubIcon {
  font-size: medium;
}

.LicensePlateInformationPanel-References {
  text-align: center;
  padding: 2vh;
  width: 100%;
}

.ReferenceLink {
  color: #fff;
  text-decoration: none;
  font-size: medium;
}

/* unvisited link */
a:link {
  color: #fff;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #fff;
}

/* mouse over link */
a:hover {
  color: var(--color-app);
}

:root {
  --bg-color-A-1968-1969: #fff;
  --border-color-A-1968-1969: #fff;
  --font-color-A-1968-1969: #8f4749;

  --bg-color-B-1968-1969: #165fa8;
  --border-color-B-1968-1969: #165fa8;
  --font-color-B-1968-1969: #fff;

  /* --bg-color-C-1968-1969: #165fa8;
  --border-color-C-1968-1969: #165fa8;
  --font-color-C-1968-1969: #fff; */

  --bg-color-D-1968-1969: #fff;
  --border-color-D-1968-1969: #fff;
  --font-color-D-1968-1969: #2c8b77;

  --bg-color-E-1968-1969: #f0a635;
  --border-color-E-1968-1969: #f0a635;
  --font-color-E-1968-1969: #000;
}

.Olimpycs {
  font-weight: bold;
}

.LP-A-1968-1969 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BL BC BC BC BR BR';
  border-color: #fff;
  border-color: var(--border-color-A-1968-1969);
}
.Color-A-1968-1969 {
  background-color: #fff;
  background-color: var(--bg-color-A-1968-1969);
  color: #8f4749;
  color: var(--font-color-A-1968-1969);
}

.LP-B-1968-1969 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BL BC BC BC BR BR';
  border-color: #165fa8;
  border-color: var(--border-color-B-1968-1969);
}
.Color-B-1968-1969 {
  background-color: #165fa8;
  background-color: var(--bg-color-B-1968-1969);
  color: #fff;
  color: var(--font-color-B-1968-1969);
}

.LP-C-1968-1969 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BL BC BC BC BR BR';
  border-color: var(--border-color-C-1968-1969);
}
.Color-C-1968-1969 {
  background-color: var(--bg-color-C-1968-1969);
  color: var(--font-color-C-1968-1969);
}

.LP-D-1968-1969 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BL BC BC BC BR BR';
  border-color: #fff;
  border-color: var(--border-color-D-1968-1969);
}
.Color-D-1968-1969 {
  background-color: #fff;
  background-color: var(--bg-color-D-1968-1969);
  color: #2c8b77;
  color: var(--font-color-D-1968-1969);
}

.LP-E-1968-1969 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BL BC BC BC BR BR';
  border-color: #f0a635;
  border-color: var(--border-color-E-1968-1969);
}
.Color-E-1968-1969 {
  background-color: #f0a635;
  background-color: var(--bg-color-E-1968-1969);
  color: #000;
  color: var(--font-color-E-1968-1969);
}

.LP-X-1968-1969 {
  grid-template-areas:
    'ML TL TL TL TR TR TR'
    'MC BL BL BC BC BR BR';
  border-color: #fff;
  border-color: var(--border-color-A-1968-1969);
}

.LP-Y-1968-1969 {
  grid-template-areas:
    'TL TR TR TR TR TR TR'
    'BL BR BR BR BR BR BR';
  border-color: #165fa8;
  border-color: var(--border-color-B-1968-1969);
}

:root {
  --bg-color-A-1970-1971: #fff;
  --border-color-A-1970-1971: #143e6f;
  --font-color-A-1970-1971: #143e6f;

  --bg-color-B-1970-1971: #fff;
  --border-color-B-1970-1971: #8e1622;
  --font-color-B-1970-1971: #8e1622;

  --bg-color-C-1970-1971: #c4cccc;
  --border-color-C-1970-1971: #000;
  --font-color-C-1970-1971: #000;

  --bg-color-D-1970-1971: #f7b039;
  --border-color-D-1970-1971: #000;
  --font-color-D-1970-1971: #000;

  --bg-color-E-1970-1971: #fff;
  --border-color-E-1970-1971: #2c8b77;
  --font-color-E-1970-1971: #2c8b77;
}

.LP-A-1970-1971 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #143e6f;
  border-color: var(--border-color-A-1970-1971);
}

.Color-A-1970-1971 {
  background-color: #fff;
  background-color: var(--bg-color-A-1970-1971);
  color: #143e6f;
  color: var(--font-color-A-1970-1971);
}

.LP-B-1970-1971 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #8e1622;
  border-color: var(--border-color-B-1970-1971);
}

.Color-B-1970-1971 {
  background-color: #fff;
  background-color: var(--bg-color-B-1970-1971);
  color: #8e1622;
  color: var(--font-color-B-1970-1971);
}

.LP-C-1970-1971 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #000;
  border-color: var(--border-color-C-1970-1971);
}

.Color-C-1970-1971 {
  background-color: #c4cccc;
  background-color: var(--bg-color-C-1970-1971);
  color: #000;
  color: var(--font-color-C-1970-1971);
}

.LP-D-1970-1971 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #000;
  border-color: var(--border-color-D-1970-1971);
}

.Color-D-1970-1971 {
  background-color: #f7b039;
  background-color: var(--bg-color-D-1970-1971);
  color: #000;
  color: var(--font-color-D-1970-1971);
}

.LP-E-1970-1971 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #2c8b77;
  border-color: var(--border-color-E-1970-1971);
}
.Color-E-1970-1971 {
  background-color: #fff;
  background-color: var(--bg-color-E-1970-1971);
  color: #2c8b77;
  color: var(--font-color-E-1970-1971);
}

:root {
  --bg-color-A-1972-1973: #143e6f;
  --border-color-A-1972-1973: white;
  --font-color-A-1972-1973: white;

  --bg-color-B-1972-1973: #692426;
  --border-color-B-1972-1973: white;
  --font-color-B-1972-1973: white;

  --bg-color-C-1972-1973: #c4cccc;
  --border-color-C-1972-1973: black;
  --font-color-C-1972-1973: black;

  --bg-color-D-1972-1973: black;
  --border-color-D-1972-1973: white;
  --font-color-D-1972-1973: white;
}

.LP-A-1972-1973 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: white;
  border-color: var(--border-color-A-1972-1973);
}

.Color-A-1972-1973 {
  background-color: #143e6f;
  background-color: var(--bg-color-A-1972-1973);
  color: white;
  color: var(--font-color-A-1972-1973);
}

.LP-B-1972-1973 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: white;
  border-color: var(--border-color-B-1972-1973);
}

.Color-B-1972-1973 {
  background-color: #692426;
  background-color: var(--bg-color-B-1972-1973);
  color: white;
  color: var(--font-color-B-1972-1973);
}

.LP-C-1972-1973 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: black;
  border-color: var(--border-color-C-1972-1973);
}

.Color-C-1972-1973 {
  background-color: #c4cccc;
  background-color: var(--bg-color-C-1972-1973);
  color: black;
  color: var(--font-color-C-1972-1973);
}

.LP-D-1972-1973 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: white;
  border-color: var(--border-color-D-1972-1973);
}

.Color-D-1972-1973 {
  background-color: black;
  background-color: var(--bg-color-D-1972-1973);
  color: white;
  color: var(--font-color-D-1972-1973);
}

:root {
  --bg-color-A-1974-1975: #fff;
  --border-color-A-1974-1975: #fff;
  --font-color-A-1974-1975: #3dbeea;

  --bg-color-B-1974-1975: #fff;
  --border-color-B-1974-1975: #fff;
  --font-color-B-1974-1975: #d03847;

  --bg-color-C-1974-1975: #fff;
  --border-color-C-1974-1975: #fff;
  --font-color-C-1974-1975: #000;

  --bg-color-D-1974-1975: #d6a330;
  --border-color-D-1974-1975: #d6a330;
  --font-color-D-1974-1975: #0a3c77;
}

.LP-A-1974-1975 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #fff;
  border-color: var(--border-color-A-1974-1975);
}

.Color-A-1974-1975 {
  background-color: #fff;
  background-color: var(--bg-color-A-1974-1975);
  color: #3dbeea;
  color: var(--font-color-A-1974-1975);
}

.LP-B-1974-1975 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #fff;
  border-color: var(--border-color-B-1974-1975);
}

.Color-B-1974-1975 {
  background-color: #fff;
  background-color: var(--bg-color-B-1974-1975);
  color: #d03847;
  color: var(--font-color-B-1974-1975);
}

.LP-C-1974-1975 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #fff;
  border-color: var(--border-color-C-1974-1975);
}

.Color-C-1974-1975 {
  background-color: #fff;
  background-color: var(--bg-color-C-1974-1975);
  color: #000;
  color: var(--font-color-C-1974-1975);
}

.LP-D-1974-1975 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #d6a330;
  border-color: var(--border-color-D-1974-1975);
}

.Color-D-1974-1975 {
  background-color: #d6a330;
  background-color: var(--bg-color-D-1974-1975);
  color: #0a3c77;
  color: var(--font-color-D-1974-1975);
}

:root {
  --bg-color-A-1976-1977: #8bceec;
  --border-color-A-1976-1977: #8bceec;
  --font-color-A-1976-1977: #000;

  --bg-color-B-1976-1977: #d2c5a4;
  --border-color-B-1976-1977: #d2c5a4;
  --font-color-B-1976-1977: #000;

  --bg-color-C-1976-1977: #c03529;
  --border-color-C-1976-1977: #c03529;
  --font-color-C-1976-1977: #fff;

  --bg-color-D-1976-1977: #d6a330;
  --border-color-D-1976-1977: #d6a330;
  --font-color-D-1976-1977: #0a3c77;
}

.LP-A-1976-1977 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #8bceec;
  border-color: var(--border-color-A-1976-1977);
}

.Color-A-1976-1977 {
  background-color: #8bceec;
  background-color: var(--bg-color-A-1976-1977);
  color: #000;
  color: var(--font-color-A-1976-1977);
}

.LP-B-1976-1977 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #d2c5a4;
  border-color: var(--border-color-B-1976-1977);
}

.Color-B-1976-1977 {
  background-color: #d2c5a4;
  background-color: var(--bg-color-B-1976-1977);
  color: #000;
  color: var(--font-color-B-1976-1977);
}

.LP-C-1976-1977 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #c03529;
  border-color: var(--border-color-C-1976-1977);
}

.Color-C-1976-1977 {
  background-color: #c03529;
  background-color: var(--bg-color-C-1976-1977);
  color: #fff;
  color: var(--font-color-C-1976-1977);
}

.LP-D-1976-1977 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #d6a330;
  border-color: var(--border-color-D-1976-1977);
}

.Color-D-1976-1977 {
  background-color: #d6a330;
  background-color: var(--bg-color-D-1976-1977);
  color: #0a3c77;
  color: var(--font-color-D-1976-1977);
}

:root {
  --bg-color-A-1978-1979: #000;
  --border-color-A-1978-1979: #e89531;
  --font-color-A-1978-1979: #e89531;

  --bg-color-B-1978-1979: #fff;
  --border-color-B-1978-1979: green;
  --font-color-B-1978-1979: green;

  --bg-color-C-1978-1979: #0c1d52;
  --border-color-C-1978-1979: #fff;
  --font-color-C-1978-1979: #fff;

  --bg-color-D-1978-1979: #e0a63f;
  --border-color-D-1978-1979: #000;
  --font-color-D-1978-1979: #000;

  --bg-color-E-1978-1979: #fff;
  --border-color-E-1978-1979: #c74112;
  --font-color-E-1978-1979: #c74112;
}

.LP-A-1978-1979 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #e89531;
  border-color: var(--border-color-A-1978-1979);
}

.Color-A-1978-1979 {
  background-color: #000;
  background-color: var(--bg-color-A-1978-1979);
  color: #e89531;
  color: var(--font-color-A-1978-1979);
}

.LP-B-1978-1979 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: green;
  border-color: var(--border-color-B-1978-1979);
}

.Color-B-1978-1979 {
  background-color: #fff;
  background-color: var(--bg-color-B-1978-1979);
  color: green;
  color: var(--font-color-B-1978-1979);
}

.LP-C-1978-1979 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #fff;
  border-color: var(--border-color-C-1978-1979);
}

.Color-C-1978-1979 {
  background-color: #0c1d52;
  background-color: var(--bg-color-C-1978-1979);
  color: #fff;
  color: var(--font-color-C-1978-1979);
}

.LP-D-1978-1979 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BC BC BC BC BC BR';
  border-color: #000;
  border-color: var(--border-color-D-1978-1979);
}

.Color-D-1978-1979 {
  background-color: #e0a63f;
  background-color: var(--bg-color-D-1978-1979);
  color: #000;
  color: var(--font-color-D-1978-1979);
}

.LP-E-1978-1979 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BC BC BC BC BC BR';
  border-color: #c74112;
  border-color: var(--border-color-E-1978-1979);
}

.Color-E-1978-1979 {
  background-color: #fff;
  background-color: var(--bg-color-E-1978-1979);
  color: #c74112;
  color: var(--font-color-E-1978-1979);
}

:root {
  --bg-color-A-1980-1981: #094630;
  --border-color-A-1980-1981: #094630;
  --font-color-A-1980-1981: #fff;

  --bg-color-B-1980-1981: #73331b;
  --border-color-B-1980-1981: #73331b;
  --font-color-B-1980-1981: #fff;

  --bg-color-C-1980-1981: #404044;
  --border-color-C-1980-1981: #404044;
  --font-color-C-1980-1981: #e76f2f;

  --bg-color-D-1980-1981: #b13130;
  --border-color-D-1980-1981: #b13130;
  --font-color-D-1980-1981: #fff;
}

.LP-A-1980-1981 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #094630;
  border-color: var(--border-color-A-1980-1981);
}

.Color-A-1980-1981 {
  background-color: #094630;
  background-color: var(--bg-color-A-1980-1981);
  color: #fff;
  color: var(--font-color-A-1980-1981);
}

.LP-B-1980-1981 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #73331b;
  border-color: var(--border-color-B-1980-1981);
}

.Color-B-1980-1981 {
  background-color: #73331b;
  background-color: var(--bg-color-B-1980-1981);
  color: #fff;
  color: var(--font-color-B-1980-1981);
}

.LP-C-1980-1981 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #404044;
  border-color: var(--border-color-C-1980-1981);
}

.Color-C-1980-1981 {
  background-color: #404044;
  background-color: var(--bg-color-C-1980-1981);
  color: #e76f2f;
  color: var(--font-color-C-1980-1981);
}

.LP-D-1980-1981 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BC BC BC BC BC BR';
  border-color: #b13130;
  border-color: var(--border-color-D-1980-1981);
}

.Color-D-1980-1981 {
  background-color: #b13130;
  background-color: var(--bg-color-D-1980-1981);
  color: #fff;
  color: var(--font-color-D-1980-1981);
}

:root {
  --bg-color-A-1982-1983: #e5efeb;
  --border-color-A-1982-1983: #e5efeb;
  --font-color-A-1982-1983: #0e1f39;

  --bg-color-B-1982-1983: #e5efeb;
  --border-color-B-1982-1983: #e5efeb;
  --font-color-B-1982-1983: green;

  --bg-color-C-1982-1983: #f39855;
  --border-color-C-1982-1983: #f39855;
  --font-color-C-1982-1983: #000;

  --bg-color-D-1982-1983: #8bceec;
  --border-color-D-1982-1983: #8bceec;
  --font-color-D-1982-1983: #000;
}

.LP-A-1982-1983 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #e5efeb;
  border-color: var(--border-color-A-1982-1983);
}

.Color-A-1982-1983 {
  background-color: #e5efeb;
  background-color: var(--bg-color-A-1982-1983);
  color: #0e1f39;
  color: var(--font-color-A-1982-1983);
}

.LP-B-1982-1983 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #e5efeb;
  border-color: var(--border-color-B-1982-1983);
}

.Color-B-1982-1983 {
  background-color: #e5efeb;
  background-color: var(--bg-color-B-1982-1983);
  color: green;
  color: var(--font-color-B-1982-1983);
}

.LP-C-1982-1983 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #f39855;
  border-color: var(--border-color-C-1982-1983);
}

.Color-C-1982-1983 {
  background-color: #f39855;
  background-color: var(--bg-color-C-1982-1983);
  color: #000;
  color: var(--font-color-C-1982-1983);
}

.LP-D-1982-1983 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BC BC BC BC BC BR';
  border-color: #8bceec;
  border-color: var(--border-color-D-1982-1983);
}

.Color-D-1982-1983 {
  background-color: #8bceec;
  background-color: var(--bg-color-D-1982-1983);
  color: #000;
  color: var(--font-color-D-1982-1983);
}

:root {
  --bg-color-A-1984-1985: #0e3d79;
  --border-color-A-1984-1985: #c5a63d;
  --font-color-A-1984-1985: #c5a63d;

  --bg-color-B-1984-1985: #c23b0a;
  --border-color-B-1984-1985: #fff;
  --font-color-B-1984-1985: #fff;

  --bg-color-C-1984-1985: #1e6041;
  --border-color-C-1984-1985: #fff;
  --font-color-C-1984-1985: #fff;

  --bg-color-D-1984-1985: #e98f09;
  --border-color-D-1984-1985: #000;
  --font-color-D-1984-1985: #000;
}

.LP-A-1984-1985 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #c5a63d;
  border-color: var(--border-color-A-1984-1985);
}

.Color-A-1984-1985 {
  background-color: #0e3d79;
  background-color: var(--bg-color-A-1984-1985);
  color: #c5a63d;
  color: var(--font-color-A-1984-1985);
}

.LP-B-1984-1985 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #fff;
  border-color: var(--border-color-B-1984-1985);
}

.Color-B-1984-1985 {
  background-color: #c23b0a;
  background-color: var(--bg-color-B-1984-1985);
  color: #fff;
  color: var(--font-color-B-1984-1985);
}

.LP-C-1984-1985 {
  grid-template-areas:
    'TL TL TL TC TR TR TR'
    'BL BC BC BC BC BC BR';
  border-color: #fff;
  border-color: var(--border-color-C-1984-1985);
}

.Color-C-1984-1985 {
  background-color: #1e6041;
  background-color: var(--bg-color-C-1984-1985);
  color: #fff;
  color: var(--font-color-C-1984-1985);
}

.LP-D-1984-1985 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BL BC BC BC BC BC BR';
  border-color: #000;
  border-color: var(--border-color-D-1984-1985);
}

.Color-D-1984-1985 {
  background-color: #e98f09;
  background-color: var(--bg-color-D-1984-1985);
  color: #000;
  color: var(--font-color-D-1984-1985);
}

:root {
  --bg-color-A-1986-1991: #e2d999;
  --border-color-A-1986-1991: #1b456e;
  --font-color-A-1986-1991: #1b456e;

  --bg-color-B-1986-1991: #191f3b;
  --border-color-B-1986-1991: #fff;
  --font-color-B-1986-1991: #fff;

  --bg-color-C-1986-1991: #87c9ec;
  --border-color-C-1986-1991: #000;
  --font-color-C-1986-1991: #000;

  --bg-color-D-1986-1991: #e2d999;
  --border-color-D-1986-1991: #1b456e;
  --font-color-D-1986-1991: #1b456e;

  --bg-color-E-1986-1991: #94c28e;
  --border-color-E-1986-1991: #1b456e;
  --font-color-E-1986-1991: #1b456e;

  --bg-color-F-1986-1991: #f38d13;
  --border-color-F-1986-1991: #000;
  --font-color-F-1986-1991: #000;
}

.LP-A-1986-1991 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #1b456e;
  border-color: var(--border-color-A-1986-1991);
}

.Color-A-1986-1991 {
  background-color: #e2d999;
  background-color: var(--bg-color-A-1986-1991);
  color: #1b456e;
  color: var(--font-color-A-1986-1991);
}

.LP-B-1986-1991 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #fff;
  border-color: var(--border-color-B-1986-1991);
}

.Color-B-1986-1991 {
  background-color: #191f3b;
  background-color: var(--bg-color-B-1986-1991);
  color: #fff;
  color: var(--font-color-B-1986-1991);
}

.LP-C-1986-1991 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #000;
  border-color: var(--border-color-C-1986-1991);
}

.Color-C-1986-1991 {
  background-color: #87c9ec;
  background-color: var(--bg-color-C-1986-1991);
  color: #000;
  color: var(--font-color-C-1986-1991);
}

.LP-D-1986-1991 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #1b456e;
  border-color: var(--border-color-D-1986-1991);
}

.Color-D-1986-1991 {
  background-color: #e2d999;
  background-color: var(--bg-color-D-1986-1991);
  color: #1b456e;
  color: var(--font-color-D-1986-1991);
}

.LP-E-1986-1991 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #1b456e;
  border-color: var(--border-color-E-1986-1991);
}

.Color-E-1986-1991 {
  background-color: #94c28e;
  background-color: var(--bg-color-E-1986-1991);
  color: #1b456e;
  color: var(--font-color-E-1986-1991);
}

.LP-F-1986-1991 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #1b456e;
  border-color: var(--border-color-E-1986-1991);
}

.Color-F-1986-1991 {
  background-color: #f38d13;
  background-color: var(--bg-color-F-1986-1991);
  color: #000;
  color: var(--font-color-F-1986-1991);
}

:root {
  --bg-color-A-1992-1997: #fff;
  --border-color-A-1992-1997: #197f3b;
  --font-color-A-1992-1997: #197f3b;

  --bg-color-B-1992-1997: #f38d13;
  --border-color-B-1992-1997: #fff;
  --font-color-B-1992-1997: #fff;

  --bg-color-C-1992-1997: #e5b323;
  --border-color-C-1992-1997: #56b65c;
  --font-color-C-1992-1997: #56b65c;

  --bg-color-D-1992-1997: #fff;
  --border-color-D-1992-1997: #197f3b;
  --font-color-D-1992-1997: #197f3b;

  --bg-color-E-1992-1997: #0f8d78;
  --border-color-E-1992-1997: #fff;
  --font-color-E-1992-1997: #fff;

  --bg-color-F-1992-1997: #306fab;
  --border-color-F-1992-1997: #fff;
  --font-color-F-1992-1997: #fff;

  --bg-color-G-1992-1997: #835a35;
  --border-color-G-1992-1997: #fff;
  --font-color-G-1992-1997: #fff;

  --bg-color-H-1992-1997: #cdb094;
  --border-color-H-1992-1997: #80675e;
  --font-color-H-1992-1997: #80675e;

  --bg-color-I-1992-1997: #fff;
  --border-color-I-1992-1997: #3c5487;
  --font-color-I-1992-1997: #3c5487;
}

.LP-A-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #197f3b;
  border-color: var(--border-color-A-1992-1997);
}

.Color-A-1992-1997 {
  background-color: #fff;
  background-color: var(--bg-color-A-1992-1997);
  color: #197f3b;
  color: var(--font-color-A-1992-1997);
}

.LP-B-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #fff;
  border-color: var(--border-color-B-1992-1997);
}

.Color-B-1992-1997 {
  background-color: #f38d13;
  background-color: var(--bg-color-B-1992-1997);
  color: #fff;
  color: var(--font-color-B-1992-1997);
}

.LP-C-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #56b65c;
  border-color: var(--border-color-C-1992-1997);
}

.Color-C-1992-1997 {
  background-color: #e5b323;
  background-color: var(--bg-color-C-1992-1997);
  color: #56b65c;
  color: var(--font-color-C-1992-1997);
}

.LP-D-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #197f3b;
  border-color: var(--border-color-D-1992-1997);
}

.Color-D-1992-1997 {
  background-color: #fff;
  background-color: var(--bg-color-D-1992-1997);
  color: #197f3b;
  color: var(--font-color-D-1992-1997);
}

.LP-E-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #fff;
  border-color: var(--border-color-E-1992-1997);
}

.Color-E-1992-1997 {
  background-color: #0f8d78;
  background-color: var(--bg-color-E-1992-1997);
  color: #fff;
  color: var(--font-color-E-1992-1997);
}

.LP-F-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #fff;
  border-color: var(--border-color-E-1992-1997);
}

.Color-F-1992-1997 {
  background-color: #306fab;
  background-color: var(--bg-color-F-1992-1997);
  color: #fff;
  color: var(--font-color-F-1992-1997);
}

.LP-G-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #fff;
  border-color: var(--border-color-G-1992-1997);
}
.Color-G-1992-1997 {
  background-color: #835a35;
  background-color: var(--bg-color-G-1992-1997);
  color: #fff;
  color: var(--font-color-G-1992-1997);
}

.LP-H-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'BC BC BC BC BC BC BC';
  border-color: #80675e;
  border-color: var(--border-color-H-1992-1997);
}
.Color-H-1992-1997 {
  background-color: #cdb094;
  background-color: var(--bg-color-H-1992-1997);
  color: #80675e;
  color: var(--font-color-H-1992-1997);
}

.LP-I-1992-1997 {
  grid-template-areas:
    'TC TC TC TC TC TC TC'
    'MC MC MC MC MC MC MC'
    'BC BC BC BC BC BC BC';
  border-color: #3c5487;
  border-color: var(--border-color-I-1992-1997);
}
.Color-I-1992-1997 {
  background-color: #fff;
  background-color: var(--bg-color-I-1992-1997);
  color: #3c5487;
  color: var(--font-color-I-1992-1997);
}

.AbbreviationsPanel {
  text-align: center;
  padding: 2vh;
  width: 100%;
  color: var(--font-color-app);
  font-size: medium;
}

.AbbreviationsPanel-Block {
  padding: 1.5vh;
}
.Abbreviations {
  font-weight: bold;
  /* color: var(--color-app); */
  text-shadow: 1px 2px 2px var(--color-app);
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /*---*/
  -webkit-column-count: 3;
          column-count: 3;
  grid-column-gap: 0;
  -webkit-column-gap: 0;
          column-gap: 0;
  width: 100%;
}

@media screen and (max-width: 750px) {
  ul {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

.PageNotFound {
  height: inherit;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.NavBarHeader {
  background-color: inherit;
  color: var(--font-color-app);
  white-space: nowrap;
}

.NavBarHeader-Title {
  align-items: center;
  font-size: 1em;
  justify-content: center;
  line-height: 20px;
  padding: 20px 0;
  text-align: left;
}

.NavBar-Icon {
  font-size: 1.8em;
  vertical-align: middle;
}

.SideNavBar {
  background: var(--sidenav-background-app);
  height: var(--container-app-height);
  /* width:         calc(auto - 20px); */
  width: 5vh;
}

.SideNavBarExpanded {
  overflow: overlay;
}

.NavItemText {
  font-size: 1.5em;
  vertical-align: middle;
}

.NavSubItemText {
  /* padding-left: 10; */
  font-size: 1em;
  vertical-align: middle;
}

/*search for isASmallDeviceByHeight(501) */
@media only screen and (max-device-height: 500px) {
  .SideNavBar {
    /* overflow-Y: overlay; */
    /* overflow: visible; */
    overflow-y: overlay;
    overflow-x: hidden;
  }
}

.BodyComponent {
  color: white;
  /* flex-grow: 2; */
  height: var(--container-app-height);
  /* height: super; */
  /* overflow: scroll !important; */
  /* padding: 10px 15px 10px 15px; */
  /* margin-top: 0; */
  overflow: overlay;
  /* overflow-y: auto; */
  overflow-x: hidden;
  /* width: 100%; */
  /* max-width: 100%; */
  padding-bottom: 15px;
}

.Routes {
  /* display: flex; */
  height: var(--container-app-height);
  /* height: super; */
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  /* height: 100%; */
}

.LanguageSelector {
  color: var(--font-color-app);
  background-color: var(--dark-color-app);
  text-align: right;
  font-size: 2vh;
  height: var(--language-selector-height);
  /* height: 3%; */
  /* justify-content: left; */
  /* display: inline-block; */
  /* vertical-align: middle; */
  /* margin: 0 0 0 0;
    padding: 0 0 0 0; */
}

.LanguageSelector-Dropdown {
  display: inline-block;
  position: relative;
  height: inherit;
  text-align: left;
}

