.NavBar-Icon {
  font-size: 1.8em;
  vertical-align: middle;
}

.SideNavBar {
  background: var(--sidenav-background-app);
  height: var(--container-app-height);
  /* width:         calc(auto - 20px); */
  width: 5vh;
}

.SideNavBarExpanded {
  overflow: overlay;
}

.NavItemText {
  font-size: 1.5em;
  vertical-align: middle;
}

.NavSubItemText {
  /* padding-left: 10; */
  font-size: 1em;
  vertical-align: middle;
}

/*search for isASmallDeviceByHeight(501) */
@media only screen and (max-device-height: 500px) {
  .SideNavBar {
    /* overflow-Y: overlay; */
    /* overflow: visible; */
    overflow-y: overlay;
    overflow-x: hidden;
  }
}
