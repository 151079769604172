.HomePage {
  margin: 10px 50px 10px 50px;
  margin: 1vh 5vw;
}

.HomePage-Logo {
  width: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: shadowAnimation;
  filter: drop-shadow(0 0 1rem white);
  animation: shadowAnimation 1.5s alternate infinite;
}
@keyframes shadowAnimation {
  /* 33% {filter: drop-shadow(0 0 1.75rem green);}
  66% {filter: drop-shadow(0 0 1.75rem white);}
  99% {filter: drop-shadow(0 0 1.75rem red);} */
  100% {
    filter: drop-shadow(0 0 1rem crimson);
  }
}

.HomePage-Logo img {
  padding-top: 2vh;
  width: 30%;
  height: auto;
}

.HomePage p {
  text-align: center;
}

.AuthorLink {
  color: var(--color-app);
}

.AuthorLink a {
  color: var(--color-app);
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-device-width: 1000px) {
  .HomePage {
    font-size: large;
  }
  .HomePage-Logo img {
    width: 50%;
  }
  .HomePage p {
    text-indent: 0px;
    text-align: center;
  }
}

@media only screen and (max-device-width: 500px) {
  .HomePage {
    font-size: medium;
  }
  .HomePage-Logo img {
    width: 100%;
  }
  .HomePage p {
    text-indent: 0px;
    text-align: center;
  }
}

.Author {
  text-align: center;
  padding-bottom: 40px;
}
