:root {
  --font-color-app: #fff;
  --background-color: #282c34;
  --color-app: #b50d15;
  --dark-color-app: #9f131a;
  /* --background-color: #1d1e20;
  --color-app: #3d71e0;
  --dark-color-app: #255cd4; 
  */
  --sidenav-background-app: var(--dark-color-app);

  --hover-item-bg-color: #fff;
  --default-item-bg-color: var(--background-color); /*#000;*/
  --selected-item-bg-color: var(--dark-color-app); /*#006341;*/

  --hover-item-font-color: #000;
  --default-item-font-color: #fff; /*var(--dark-color-app);*/
  --selected-item-font-color: #fff;
  --filtered-item-font-color: #fff;
  --container-app-height: 100vh;
  --single-img-license-plate-width: 70vw;
}

.App {
  align-items: center;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

h2 {
  text-align: center;
  color: var(--font-color-app);
  text-shadow: 1px 2px 2px var(--color-app);
  margin: 15px 15px 15px 15px;
  padding: 0.5vh;
}

h3 {
  text-align: center;
  color: var(--font-color-app);
  text-shadow: 1px 2px 2px var(--color-app);
  margin: 0px 0px 10px 0px;
}

/* h4,
label {
  text-align: center;
  vertical-align: middle;
  color: var(--font-color-app);
  text-shadow: 1px 2px 2px var(--color-app);
  margin: 15px 15px 15px 15px;
} */
h4 {
  text-align: center;
  text-shadow: 1px 2px 2px black;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  80% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(30deg);
  }
}

.MexOptionsPanel {
  border-radius: 20px;
  border: 1px solid var(--background-color);
  /* background-color: var(--selected-item-bg-color); */
  /*   
  &:hover {
    transform: translate3d(0px, 0px, -250px);
  } */
}

.YearChoice {
  font-size: smaller;
  background-color: inherit;
  border: 2px solid #555555;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
}

.Selected {
  background-color: var(--selected-item-bg-color);
  color: var(--selected-item-fc-color);
}

.MissingItem {
  background-color: #000;
  color: var(--selected-item-fc-color);
  cursor: default;
  font-size: smaller;
}

@media only screen and (max-device-width: 500px) {
  .YearChoice {
    padding: 5px 10px;
  }
}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}
