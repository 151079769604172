.LanguageSelector {
  color: var(--font-color-app);
  background-color: var(--dark-color-app);
  text-align: right;
  font-size: 2vh;
  height: var(--language-selector-height);
  /* height: 3%; */
  /* justify-content: left; */
  /* display: inline-block; */
  /* vertical-align: middle; */
  /* margin: 0 0 0 0;
    padding: 0 0 0 0; */
}

.LanguageSelector-Dropdown {
  display: inline-block;
  position: relative;
  height: inherit;
  text-align: left;
}
