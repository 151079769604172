.BodyComponent {
  color: white;
  /* flex-grow: 2; */
  height: var(--container-app-height);
  /* height: super; */
  /* overflow: scroll !important; */
  /* padding: 10px 15px 10px 15px; */
  /* margin-top: 0; */
  overflow: overlay;
  /* overflow-y: auto; */
  overflow-x: hidden;
  /* width: 100%; */
  /* max-width: 100%; */
  padding-bottom: 15px;
}

.Routes {
  /* display: flex; */
  height: var(--container-app-height);
  /* height: super; */
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  /* height: 100%; */
}
