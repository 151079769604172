.LicensePlateInformationPanel-Options {
  text-align: center;
  padding: 2vh;
  width: 100%;
}
.LicensePlateInformationPanel-Figures {
  padding-top: 1vh;
}

.dropdown > button {
  background-color: var(--dark-color-app);
  color: var(--font-color-app);
  font-size: medium;
}

.Body {
  font-size: 1.6em;
  line-height: 1.15em;
}
.Footer {
  font-size: 0.7em;
}
.Header {
  font-size: 0.7em;
}

.SmallText {
  /* padding: 0.1em; */
  font-size: 0.55em;
  line-height: 2.3em;
  font-weight: bold;
}
.X-SmallText {
  font-size: 0.35em;
  line-height: 1.3em;
  font-weight: bold;
  margin: 0;
}

.HorizontalText {
  /* padding: 0.1em; */
  font-size: 0.8em;
  line-height: 1.7em;
  padding: 0;
  text-align: left;
}

.VerticalText {
  font-size: 1.4em;
  line-height: 0.9em;
  font-weight: bold;
}

.TL {
  grid-area: TL;
  padding: 0em;
}
.TC {
  grid-area: TC;
  padding: 0em;
}
.TR {
  grid-area: TR;
  padding: 0em;
}

.ML {
  grid-area: ML;
  padding: 0em;
}
.MC {
  grid-area: MC;
  padding: 0em;
}
.MR {
  grid-area: MR;
  padding: 0em;
}

.BL {
  grid-area: BL;
}
.BC {
  grid-area: BC;
}
.BR {
  grid-area: BR;
}

.LicensePlateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1vh;
}

.LicensePlate {
  border-radius: 5px;
  padding: 0.1em 0.1em;
  text-align: center;
  font-family: Sans-serif;
  text-shadow: 0px 0.05em 0px #808080;
  /* text-shadow: 0px 0.07em 0px #808080; */
  width: 7.3em;

  /* Shadows */
  box-shadow: 10px 10px 5px #000;
  -moz-box-shadow: 10px 10px 5px #000;
  -webkit-box-shadow: 10px 10px 5px #000;
  -khtml-box-shadow: 10px 10px 5px #000;
  border-radius: 5px;
}

.LP {
  display: grid;
  padding: 0.08em 0.1em;
  border: 0.1em solid white;
}

.LicensePlateFigures {
  text-align: center;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}
td {
  padding-bottom: 2vh;
}

.VehicleTypeHeader {
  font-size: medium;
}
td > .VehicleTypeHeader {
  display: none;
}

@media only screen and (max-width: 760px) {
  thead {
    display: none;
  }
  td > .VehicleTypeHeader {
    display: block;
  }
  table {
    border-collapse: collapse;
  }
  table,
  tbody,
  tr,
  td {
    display: block;
    width: 100%;
  }
  tr {
    overflow: hidden;
  }
  td {
    padding: 1vh;
  }
  td.cell-time,
  td.cell-pax,
  td.cell-name,
  td.cell-mobile {
    float: left;
    width: 50%;
  }
}

.SubIcon {
  font-size: medium;
}

.LicensePlateInformationPanel-References {
  text-align: center;
  padding: 2vh;
  width: 100%;
}

.ReferenceLink {
  color: #fff;
  text-decoration: none;
  font-size: medium;
}

/* unvisited link */
a:link {
  color: #fff;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #fff;
}

/* mouse over link */
a:hover {
  color: var(--color-app);
}
